<template>
  <Drawer v-if="shouldBeVisible">
    <template #toggle="{ toggleDrawer }">
      <RoleControlledAction
        v-slot="{wrapper, restricted}"
        :user-role="roleName"
        :config-object="roleConfig.payOffSchedule"
      >
        <button
          :class="{ 'custom-disabled': restricted}"
          class="button button--primary button--sm"
          @click="wrapper(toggleDrawer)"
        >
          Make Payment
        </button>
      </RoleControlledAction>
    </template>
    <template #default="{ toggleDrawer }">
      <form
        v-if="formState !== FormState.success"
        class="grow-1 flex flex-col justify-between h-full"
        @submit.prevent="submit"
      >
        <div class="flex flex-col justify-between h-full">
          <div>
            <h2 class="text-lg font-bold text-primary mb-6">
              Make a payment
            </h2>
            <div class="mb-6">
              <p class="text-primary text-sm font-light leading-normal tracking-wide mb-1.5">
                Charged to payment method
              </p>
              <div class="flex items-center text-primary text-base font-bold leading-relaxed tracking-wide">
                <CardIcon :variant="options.brand" />&nbsp;
                • • • • {{ options?.cardNumber }}
              </div>
            </div>
            <div class="mb-4 flex justify-between items-center">
              <label class="flex items-center w-full">
                <input
                  v-if="nextInstallment"
                  v-model="selectedOption"
                  value="installment"
                  type="radio"
                  name="paymentOption"
                  class="mr-4"
                  @change="handlePaymentOptionChange"
                >
                <div
                  class="font-light leading-normal tracking-wide"
                >
                  <p class="text-primary">
                    Upcoming Payment
                  </p>
                  <p class="text-gray-400 text-sm">
                    (Due {{ format(new Date(nextInstallment!.dueDate), 'MMM d, yyyy') }})
                  </p>
                </div>
              </label>
              <div>
                <p class="text-center text-primary font-bold leading-relaxed tracking-wide">
                  {{ nextInstallmentAmount }}
                </p>
              </div>
            </div>
            <div class="mb-4 flex justify-between items-center">
              <label class="flex items-center w-full">
                <input
                  v-model="selectedOption"
                  value="remaining"
                  type="radio"
                  name="paymentOption"
                  class="mr-4"
                  @change="handlePaymentOptionChange"
                >
                <p
                  class="text-center text-primary font-light leading-relaxed tracking-wide"
                >
                  Remaining Balance
                </p>
              </label>
              <p class="text-center text-primary font-bold leading-relaxed tracking-wide">
                {{ formattedRemainingBalance }}
              </p>
            </div>
            <div class="mb-6 flex justify-between items-center">
              <label class="flex items-center w-full">
                <input
                  v-model="selectedOption"
                  value="customAmount"
                  type="radio"
                  name="paymentOption"
                  class="mr-4"
                  @change="handlePaymentOptionChange"
                >
                <p
                  class="
                  text-center
                  text-primary
                  font-light
                  leading-relaxed
                  tracking-wide
                  md:text-base
                  text-sm
                  whitespace-nowrap
                  md:whitespace-normal
                  "
                >
                  Custom Amount
                </p>
              </label>
              <TbInput
                v-if="selectedOption === 'customAmount'"
                ref="inputRef"
                v-model="customAmount"
                placeholder="0.00"
                type="text"
                :loading="isLoading"
              />
            </div>
            <div>
              <div
                v-if="message"
                class="px-4 py-3.5 bg-gray-100 rounded-lg border-1 border-l-4 border-gray-200"
              >
                <div class="justify-start items-start gap-3 flex">
                  <TbIcon
                    icon="credit-card-alt"
                    class="text-primary"
                  />
                  <div class="grow shrink basis-0 text-primary text-sm font-light leading-normal tracking-wide whitespace-pre-line">
                    {{ message }}
                  </div>
                </div>
              </div>
              <div class="flex justify-center items-center">
                <TbIcon
                  v-if="isLoading"
                  icon="spinner"
                  class="text-xl animate-spin"
                />
              </div>
            </div>
          </div>
          <div>
            <div v-if="errorMessage">
              <p class="text-center text-error">
                {{ errorMessage }}
              </p>
            </div>
            <FormButton
              :disabled="formState === FormState.submitting || formNotValid()"
              :is-loading="isLoading || formState === FormState.submitting"
              text="Complete Payment"
              color="primary"
            />

            <button
              class="button mt-2 button--text button--block"
              :disabled="formState === FormState.submitting"
              @click="handleDrawerClose(toggleDrawer)"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      <OffSchedulePaymentSuccess
        v-if="formState === FormState.success"
        :customer-email-address="options.email"
        :amount-paid="formattedPaidAmount"
        :payment-date="format(new Date(), 'MMM d, yyyy')"
        :brand="options.brand"
        :card-number="options.cardNumber"
        @close="handleDrawerClose(toggleDrawer)"
      />
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted,
  ref,
  watch,
  onUnmounted,
} from 'vue';
import Dinero from 'dinero.js';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useCurrencyInput, CurrencyDisplay } from 'vue-currency-input';
import { FormState } from '@/types';
import Drawer from '@/components/drawer.vue';
import CardIcon from '@/components/credit_card_icon/index.vue';
import { TbInput, TbIcon } from '@/components/tasty_bistro';
import FormButton from '@/components/form_button.vue';
import RoleControlledAction from '@/components/role_controlled_action.vue';
import { orderOriginTitle } from '@/filters/naming/origin';
import OffSchedulePaymentSuccess from './off_schedule_payment_success.vue';
import { getOffSchedulePaymentOptions, OffSchedulePaymentOptions, Installment } from './api/get_options';
import { estimateCustomPayment } from './api/estimate_custom_payment';
import { payOffSchedule } from './api/pay_off_schedule';
import { roleConfig } from './role_configurations';

const props = defineProps<{
  orderId: string;
  projectId: string;
  roleName: string;
  origin: string;
}>();

const emit = defineEmits(['refresh']);

const options = ref<OffSchedulePaymentOptions>({
  brand: null,
  currency: '',
  cardNumber: null,
  totalAmountInCents: 0,
  minimumAmountInCents: 0,
  nextDueInstallment: {
    id: '',
    amountInCents: 0,
    dueDate: '',
    status: '',
    currency: '',
  },
  installmentPaymentMessage: '',
  payoffMessage: '',
  email: null,
});

const formState = ref<FormState>(FormState.ready);
const nextInstallment = ref<Installment | undefined>(undefined);

const selectedOption = ref<'installment' | 'remaining' | 'customAmount' | null>(null);
const installmentId = ref<string | undefined>(undefined);
const amountInCents = ref<number | undefined>(undefined);
const errorMessage = ref('');
const customAmount = ref('');
const message = ref('');
const payoffMessage = ref('');
const installmentPaymentMessage = ref('');

const isLoading = ref(false);

const shouldBeVisible = computed(() => {
  const hasPaymentOptions = Boolean(options.value.nextDueInstallment.id || options.value.totalAmountInCents > 0);

  if (props.origin !== orderOriginTitle.ppm) {
    return hasPaymentOptions;
  }
  const environment = (import.meta.env.VITE_APP_ENVIRONMENT ?? '').toLowerCase();
  return (environment !== 'production') && hasPaymentOptions;
});

const currencyInputOptions = computed(() => ({
  currency: options.value.currency?.toUpperCase() || 'USD',
  currencyDisplay: CurrencyDisplay.code,
  precision: 2,
  locale: undefined,
}));

const { inputRef, numberValue } = useCurrencyInput(currencyInputOptions.value, true);

watch(() => options.value.currency, () => {
  if (inputRef.value) {
    inputRef.value.setOptions(currencyInputOptions.value);
  }
});

const formatAmountInCents = ({ amountInCents, currency }: {amountInCents: number, currency: string}) => Dinero({
  amount: amountInCents,
  currency: currency as Dinero.Currency,
}).toFormat();

const formattedPaidAmount = computed(() => {
  if (selectedOption.value === 'installment') {
    return formatAmountInCents({
      amountInCents: nextInstallment.value!.amountInCents,
      currency: nextInstallment.value!.currency,
    });
  }
  return amountInCents.value ? formatAmountInCents({
    amountInCents: amountInCents.value,
    currency: options.value.currency,
  }) : '';
});

const debouncedEstimate = debounce(async (valueInCents: number) => {
  try {
    isLoading.value = true;
    const estimate = await estimateCustomPayment({
      orderId: props.orderId,
      projectId: props.projectId,
      amountInCents: valueInCents,
    });
    message.value = estimate.customPaymentMessage;
  } catch (error) {
    console.error('Error estimating payment:', error);
    message.value = '';
  } finally {
    isLoading.value = false;
  }
}, 500);

watch(numberValue, async (newVal) => {
  if (newVal === null) {
    amountInCents.value = undefined;
    errorMessage.value = '';
    message.value = '';
    return;
  }

  const valueInCents = Math.round(newVal * 100);

  if (valueInCents < 100) {
    errorMessage.value = 'Minimum amount is $1';
    amountInCents.value = undefined;
    message.value = '';
    return;
  }

  if (valueInCents > options.value.totalAmountInCents) {
    errorMessage.value = `Maximum amount is ${formatAmountInCents({
      amountInCents: options.value.totalAmountInCents,
      currency: options.value.currency,
    })}`;
    amountInCents.value = undefined;
    message.value = '';
    return;
  }

  errorMessage.value = '';
  amountInCents.value = valueInCents;

  debouncedEstimate(valueInCents);
});

const handlePaymentOptionChange = () => {
  installmentId.value = undefined;
  amountInCents.value = undefined;
  errorMessage.value = '';
  customAmount.value = '';
  message.value = '';

  if (selectedOption.value === 'installment') {
    installmentId.value = nextInstallment.value?.id;
    message.value = installmentPaymentMessage.value;
  } else if (selectedOption.value === 'remaining') {
    amountInCents.value = options.value.totalAmountInCents;
    message.value = payoffMessage.value;
  }
};

const nextInstallmentAmount = computed(() => {
  if (!nextInstallment.value) return undefined;
  return formatAmountInCents({ amountInCents: nextInstallment.value.amountInCents, currency: nextInstallment.value.currency });
});

const formattedRemainingBalance = ref<string>('');

async function loadOptions() {
  selectedOption.value = null;
  installmentId.value = undefined;
  amountInCents.value = undefined;

  options.value = await getOffSchedulePaymentOptions({ orderId: props.orderId, projectId: props.projectId });
  nextInstallment.value = options.value.nextDueInstallment;
  payoffMessage.value = options.value.payoffMessage;
  installmentPaymentMessage.value = options.value.installmentPaymentMessage;

  formattedRemainingBalance.value = formatAmountInCents({
    amountInCents: options.value.totalAmountInCents,
    currency: options.value.currency,
  });
}

onMounted(async () => {
  await loadOptions();
});

onUnmounted(() => {
  debouncedEstimate.cancel();
});

function formNotValid() {
  const hasInstallmentId = !!installmentId.value;
  const hasAmountInCents = !!amountInCents.value;

  return !(hasInstallmentId !== hasAmountInCents);
}

async function handleDrawerClose(toggleDrawer: () => void) {
  if (formState.value === FormState.success) {
    emit('refresh');
    formState.value = FormState.ready;
    await loadOptions();
  }
  toggleDrawer();
}

async function submit() {
  formState.value = FormState.submitting;
  try {
    await payOffSchedule({
      orderId: props.orderId,
      projectId: props.projectId,
      installmentId: installmentId.value,
      amountInCents: amountInCents.value,
    });
    formState.value = FormState.success;
  } catch (error: any) {
    if (error.type) {
      errorMessage.value = error.message;
    } else {
      errorMessage.value = 'Something went wrong';
    }

    formState.value = FormState.error;
  }
}
</script>

<style lang="scss" scoped>
  .custom-disabled {
    @apply cursor-not-allowed opacity-[0.35];
  }
</style>
